import React, { useContext } from "react"

import CartLayout from "../../Layout/CartLayout"
import EncodeReceiptReminder from "./EncodeReceiptReminder"
import AddReceiptButtonMobile from "./AddReceiptButtonMobile"
import ReimbursementsListTable from "./ReimbursementsListTable"
import ReimbursementsListHeader from "./ReimbursementsListHeader"
import ReimbursementsListMobile from "./ReimbursementsListMobile"
import NoReceiptsMessage, { NoReceiptsMessageMobile } from "./NoReceiptsMessage"

import { AppContext } from "../../../context/AppContext"

const ReimbursementsList = ({
  handleClickAddReceipt,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
  location,
}) => {
  const {
    state: {
      flexmed: { receipts },
    },
  } = useContext(AppContext)

  return (
    <CartLayout
      isCentered
      desktop={8}
      fullhd={8}
      next={{
        label: "Next",
        disabled: !receipts.length,
        link: "/flexmed/personal-details",
      }}
      back={{
        label: "Back",
        link: location?.state?.prevPath || "/flexmed/benefit-type",
      }}
    >
      <AddReceiptButtonMobile handleClickAddReceipt={handleClickAddReceipt} />
      <ReimbursementsListHeader handleClickAddReceipt={handleClickAddReceipt} />
      <ReimbursementsListTable
        receipts={receipts}
        handleClickEditReceipt={handleClickEditReceipt}
        handleClickRemoveReceipt={handleClickRemoveReceipt}
      />
      <ReimbursementsListMobile
        receipts={receipts}
        handleClickEditReceipt={handleClickEditReceipt}
        handleClickRemoveReceipt={handleClickRemoveReceipt}
      />
      <NoReceiptsMessage receipts={receipts} />
      <NoReceiptsMessageMobile receipts={receipts} />
      <EncodeReceiptReminder receipts={receipts} />
    </CartLayout>
  )
}

export default ReimbursementsList
