import React, { useState, Fragment, useEffect } from "react"

const EncodeReceiptPrompt = ({ items }) => {
  const [hasOther, setHasOther] = useState(false)

  useEffect(() => {
    let receiptHasOthers = items.some(
      item =>
        item?.SKU?.includes("Other") && !item?.SKU?.includes("Other Brand")
    )

    if (receiptHasOthers) {
      setHasOther(true)
    } else setHasOther(false)
  }, [items])
  return (
    <Fragment>
      {hasOther && (
        <div className="notification is-warning has-text-centered is-size-5 mt-3">
          Partial receipts may need more time for verification. Receipts
          containing only reimbursable items are processed faster.
        </div>
      )}
    </Fragment>
  )
}

export default EncodeReceiptPrompt
