export const partialReceiptHeaders = [
  {
    label: "Receipt Items",
  },
  {
    label: "Subtotal (PHP)",
  },
  {
    label: "",
  },
]

export const fullReceiptHeaders = [
  {
    label: "Receipt Items",
  },
  {
    label: "Receipt Total (PHP)",
  },
  {
    label: "",
  },
]
