import React from "react"
import {
  partialReceiptHeaders,
  fullReceiptHeaders,
} from "../utils/FlexMedReceiptHeaders"

const EncodeReceiptItemRowHeader = ({ disableSearch }) => {
  const flexMedReceiptHeaders = disableSearch
    ? fullReceiptHeaders
    : partialReceiptHeaders
  return (
    <tr>
      {flexMedReceiptHeaders.map(header => (
        <td className="pb-1">{header.label}</td>
      ))}
    </tr>
  )
}

export default EncodeReceiptItemRowHeader
