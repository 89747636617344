import React, { useContext, useState, useEffect } from "react"

import EncodeReceiptForm from "./EncodeReceipt/EncodeReceiptForm"
import ReimbursementsList from "./EncodeReceipt/ReimbursementsList"
import { navigate } from "gatsby"

import { AppContext } from "../../context/AppContext"

const subtitleViewMessage = (
  <span>
    Click '<span className="has-text-primary">Add New Entry</span>' for{" "}
    <strong>each</strong> receipt you want to get reimbursed.{" "}
  </span>
)
const subtitleAddMessage = (
  <span>
    Click '<span className="has-text-primary">Save</span>' to{" "}
    <strong>log the receipt </strong>
    you want reimburse.
  </span>
)

const EncodeReceipt = ({ location, setSubtitleMessage }) => {
  const [currentView, setCurrentView] = useState("add")
  const [activeIndex, setActiveIndex] = useState({})
  const { state, dispatch } = useContext(AppContext)
  const {
    flexmed: { receipts },
  } = state

  const viewReceipts = () => {
    setCurrentView("view")
    setSubtitleMessage(subtitleViewMessage)
    window.scrollTo(0, 0)
  }

  const addReceipts = () => {
    setCurrentView("add")
    setSubtitleMessage(subtitleAddMessage)
  }

  const handleSubmitReceipt = () => {
    viewReceipts()
  }

  const handleClickBack = () => {
    if (receipts.length) {
      viewReceipts()
    } else {
      navigate("/flexmed/benefit-type")
    }
  }

  const handleClickEditReceipt = ({ index }) => {
    setActiveIndex(index)
    setCurrentView("edit")
    setSubtitleMessage(subtitleAddMessage)
  }

  const handleClickRemoveReceipt = ({ index }) => {
    let currentReceipts = [...receipts]
    currentReceipts.splice(index, 1)

    dispatch({ type: "SAVE_RECEIPT", payload: [...currentReceipts] })
  }

  useEffect(() => {
    if (receipts.length) {
      setCurrentView("view")
      setSubtitleMessage(subtitleViewMessage)
    } else {
      setCurrentView("add")
      setSubtitleMessage(subtitleAddMessage)
    }
  }, [])

  if (currentView === "view")
    return (
      <ReimbursementsList
        handleClickAddReceipt={() => addReceipts()}
        handleClickEditReceipt={handleClickEditReceipt}
        handleClickRemoveReceipt={handleClickRemoveReceipt}
        location={location}
      />
    )
  else if (currentView === "add" || currentView === "edit")
    return (
      <EncodeReceiptForm
        handleClickBack={handleClickBack}
        handleSubmitReceipt={handleSubmitReceipt}
        type={currentView}
        activeIndex={activeIndex}
      />
    )
  return null
}

export default EncodeReceipt
