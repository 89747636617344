import React from "react"

const ReimbursementsListButtons = ({
  index,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
}) => {
  return (
    <div className="buttons">
      <button
        type="button"
        className="button is-small is-light has-text-primary"
        onClick={() => handleClickEditReceipt({ index })}
      >
        Edit
      </button>
      <button
        type="button"
        className="button is-small is-danger"
        onClick={() => handleClickRemoveReceipt({ index })}
      >
        Remove
      </button>
    </div>
  )
}

export default ReimbursementsListButtons
