import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "../utils/flexmed.module.scss"

const EncodeReceiptReminder = ({ receipts }) => {
  const data = useStaticQuery(graphql`
    {
      receiptShort: file(relativePath: { eq: "flexmed/receipt__short.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (receipts?.length === 0)
    return (
      <div className="columns is-centered">
        <div className="column is-9 is-12-mobile is-7-fullhd">
          <section
            className={classNames(
              "message has-background-light mb-3 has-text-centered mt-3 px-3 py-2 mx-0-mobile",
              styles["encode__reminder"]
            )}
          >
            <div className="mb-1 px-3 px-1-mobile">
              <Img fluid={data?.receiptShort?.childImageSharp.fluid} />
            </div>
            <span className="is-size-5 has-text-weight-bold">
              Upload and enter receipt item and subtotal
            </span>
            <p className="is-size-6">
              Please ensure that each item on your receipt corresponds to one
              item inputted. For faster processing, please do not group similar
              items under one receipt item and subtotal.
            </p>
          </section>
        </div>
      </div>
    )
  return null
}

export default EncodeReceiptReminder
