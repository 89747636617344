import React from 'react'
import classNames from "classnames"

import styles from "../utils/flexmed.module.scss"

const ReimbursementsListHeader = ({ handleClickAddReceipt }) => (
  <div className={classNames(styles["encode__header"])}>
    <p className="is-size-6 has-text-weight-bold">Reimbursement List</p>
    <button
      type="button"
      className="button is-primary is-hidden-mobile"
      onClick={handleClickAddReceipt}
    >
      + Add New Entry
        </button>
  </div>
)

export default ReimbursementsListHeader