import React, { useContext, useState } from "react"
import { Formik, Form } from "formik"

import { EncodeReceiptCart } from "../Cart"
import UploadReceipt from "./UploadReceipt"
import CartLayout from "../../Layout/CartLayout"
import EncodeFullReceipt from "./EncodeFullReceipt"
import EncodeReceiptDetails from "./EncodeReceiptDetails"
import EncodeReceiptItems from "./EncodeReceiptItems"
import EncodeOnlinePurchase from "./EncodeOnlinePurchase"
import EncodeReceiptPrompt from "./EncodeReceiptPrompt"

import { hasUploadedRequiredFiles } from "../../Upload/services/uploadDocuments"
import { AppContext } from "../../../context/AppContext"
import {
  receiptItemsValidationSchema,
  encodeReceiptInitialValues,
} from "../utils/formData"

const EncodeReceiptForm = ({
  handleClickBack,
  handleSubmitReceipt,
  type,
  activeIndex,
}) => {
  const {
    state: {
      flexmed: { receipts },
    },
    dispatch,
  } = useContext(AppContext)
  const activeReceipt = { ...receipts[activeIndex] }
  const initialFilesUploaded =
    type === "add" ? [] : receipts[activeIndex]?.uploads
  const initialReceiptValues =
    type === "add" ? encodeReceiptInitialValues : activeReceipt
  const [filesUploaded, setFilesUploaded] = useState(initialFilesUploaded)
  const [disableSearch, setDisableSearch] = useState(false)
  const isUploadComplete = values => {
    if (values?.onlinePurchase == "Yes") {
      return (
        hasUploadedRequiredFiles(filesUploaded, "pp") &&
        hasUploadedRequiredFiles(filesUploaded, "receipt")
      )
    } else {
      return hasUploadedRequiredFiles(filesUploaded, "receipt")
    }
  }

  // const checkIfItemsValid = values => {
  //   return values?.items.every(item => item.code && item.SKU)
  // }

  const handleSubmit = values => {
    if (type === "add")
      dispatch({
        type: "SAVE_RECEIPT",
        payload: [...receipts, { ...values, uploads: filesUploaded }],
      })
    else {
      let currentReceipts = receipts
      currentReceipts.splice(activeIndex, 1, {
        ...values,
        uploads: filesUploaded,
      })
      dispatch({ type: "SAVE_RECEIPT", payload: [...currentReceipts] })
    }
    if (handleSubmitReceipt) handleSubmitReceipt()
  }

  return (
    <Formik
      initialValues={initialReceiptValues}
      onSubmit={handleSubmit}
      validationSchema={receiptItemsValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form autoComplete="off">
          <CartLayout
            isCentered
            desktop={8}
            fullhd={8}
            submit={{
              label: "Save",
              disabled:
                !isUploadComplete(values) ||
                !values?.items?.length ||
                !values?.onlinePurchase?.length,
              // !checkIfItemsValid(values),
            }}
            back={{ label: "Back", callback: handleClickBack }}
            cartContainer={
              <EncodeReceiptCart
                open
                documents={filesUploaded}
                setDocuments={setFilesUploaded}
              />
            }
            promptMessage={<EncodeReceiptPrompt items={values.items} />}
          >
            <h5 class="has-text-primary mt-2"> Upload Receipt</h5>
            <hr />
            <UploadReceipt
              filesUploaded={filesUploaded}
              setFilesUploaded={setFilesUploaded}
            />
            <EncodeFullReceipt
              values={values}
              setFieldValue={setFieldValue}
              setDisableSearch={setDisableSearch}
            />
            <EncodeOnlinePurchase
              values={values}
              filesUploaded={filesUploaded}
              setFilesUploaded={setFilesUploaded}
            />
            <h5 class="has-text-primary mt-2"> Encode Receipt Details</h5>
            <hr />
            <EncodeReceiptDetails
              values={values}
              setFieldValue={setFieldValue}
            />
            <EncodeReceiptItems
              values={values}
              disableSearch={disableSearch}
              setDisableSearch={setDisableSearch}
            />
          </CartLayout>
        </Form>
      )}
    </Formik>
  )
}

export default EncodeReceiptForm
