import React from "react"

const requiredDocumentsOnlinePurchase = [
  "For bank payment: Deposit or payment slip together with the printout of the transaction form, and",
  "For credit card payment: Credit card billing statement together with the printout of the transaction form.",
]

const EncodeOnlinePurchaseDocuments = ({ onlinePurchase }) =>
  onlinePurchase === "Yes" && (
    <div className="notification is-light mt-1">
      <div className="content">
        For online purchases, employees must submit the following documents
        aside from standard requirements:
        <ul className="mt-1">
          {requiredDocumentsOnlinePurchase.map(item => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )

export default EncodeOnlinePurchaseDocuments
