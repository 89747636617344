import React from "react"
import Table from "../../Elements/Table"
import ReimbursementsListRow from "./ReimbursementsListRow"

const ReimbursementsListTable = ({
  receipts,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
}) => {
  return (
    <Table
      headers={[
        "Preview",
        "Receipt Number",
        "Date of Purchase",
        "Total Value",
        "",
      ]}
      className="my-1 is-hidden-mobile"
    >
      {receipts.map((receipt, index) => (
        <ReimbursementsListRow
          receipt={receipt}
          index={index}
          handleClickEditReceipt={handleClickEditReceipt}
          handleClickRemoveReceipt={handleClickRemoveReceipt}
        />
      ))}
    </Table>
  )
}

export default ReimbursementsListTable
