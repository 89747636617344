import * as Yup from "yup"

const yearNow = new Date(Date.now()).getFullYear()

export const personalDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("This field is required"),
  lastName: Yup.string()
    .trim()
    .required("This field is required"),
  employeeNumber: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]{3}\d{3}$/, "Invalid employee number format")
    .required("This field is required"),
  mobileNumber: Yup.string()
    .trim()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .trim()
    .email("Please input a valid email")
    .required("This field is required"),
  personalEmail: Yup.string()
    .trim()
    .notOneOf(
      [Yup.ref("emailAddress")],
      "Personal email should not be the same as work email"
    )
    .email("Please input a valid email")
    .required("This field is required"),
  designation: Yup.string()
    .trim()
    .required("This field is required"),
  civilStatus: Yup.string()
    .trim()
    .required("This field is required"),
})

export const receiptItemsValidationSchema = Yup.object().shape({
  receiptNumber: Yup.string()
    .trim()
    .required("This field is required"),

  encodeFullReceipt: Yup.string()
    .trim()
    .required("This field is required"),
  pharmacyName: Yup.string()
    .trim()
    .required("This field is required"),
  dateOfPurchase: Yup.object({
    month: Yup.string()
      .trim()
      .required("This field is required"),
    date: Yup.string()
      .trim()
      .required("This field is required"),
    year: Yup.number()
      .min(2021, "Please input a valid year")
      .max(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  items: Yup.array().of(
    Yup.object().shape({
      SKU: Yup.string()
        .trim()
        .required("Please input a valid item."),
      itemPrice: Yup.number()
        .min(0.01, "Please enter a valid subtotal")
        .required("This field is required"),
    })
  ),
})

export const personalDetailsInitialValues = {
  fullName: "",
  employeeNumber: "",
  mobileNumber: "",
  emailAddress: "",
}

export const encodeReceiptInitialValues = {
  encodeFullReceipt: "",
  receiptNumber: "",
  pharmacyName: "",
  dateOfPurchase: {
    month: "",
    date: "",
    year: "",
  },
  items: [
    {
      SKU: "",
      code: "",
      itemPrice: "",
    },
  ],
}

export const storeOptions = [
  {
    value: "Mercury Drug",
    label: "Mercury Drug",
  },
  {
    value: "Watsons Drug",
    label: "Watsons Drug",
  },
  {
    value: "The Generics Pharmacy",
    label: "The Generics Pharmacy",
  },
  {
    value: "St. Joseph Drugstore",
    label: "St. Joseph Drugstore",
  },
  {
    value: "De Guzman Drug",
    label: "De Guzman Drug",
  },
  {
    value: "Blessed Sacrament Pharmacy",
    label: "Blessed Sacrament Pharmacy",
  },
  {
    value: "Curamed Pharmacy",
    label: "Curamed Pharmacy",
  },
  {
    value: "ExpressAid Pharmacy",
    label: "ExpressAid Pharmacy",
  },
  {
    value: "Generika Drugstore",
    label: "Generika Drugstore",
  },
  {
    value: "Rose Pharmacy",
    label: "Rose Pharmacy",
  },
  {
    value: "JKB Pharmacy",
    label: "JKB Pharmacy",
  },
  {
    value: "South Star Drug",
    label: "South Star Drug",
  },
  {
    value: "MedExpress",
    label: "MedExpress",
  },
  {
    value: "Zure Rx Drug",
    label: "Zure Rx Drug",
  },
  {
    value: "Central Luzon Drug",
    label: "Central Luzon Drug",
  },
  {
    value: "St. Ilian's Pharmacy",
    label: "St. Ilian's Pharmacy",
  },
  {
    value: "Farmacia Sta. Monica",
    label: "Farmacia Sta. Monica",
  },
  {
    value: "Drugman Drughouse",
    label: "Drugman Drughouse",
  },
  {
    value: "Biochemist Drug",
    label: "Biochemist Drug",
  },
  {
    value: "Orion Drug",
    label: "Orion Drug",
  },
  {
    value: "Executive Optical",
    label: "Executive Optical",
  },
  {
    value: "Ideal Vision",
    label: "Ideal Vision",
  },
]
