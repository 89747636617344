import React, { Fragment, useState, useRef, useContext } from "react"

import Dropzone from "../../Upload/Dropzone"
import UploadErrorNotification from "../../Upload/Dropzone/UploadErrorNotification"

import { isIeOrEdge } from "../../../services/browserCompatibility"
import { uploadDocument } from "../../Upload/services/uploadDocuments"
import { AppContext } from "../../../context/AppContext"

const MAX_FILE_SIZE_IN_BYTES = 5242880
const MAX_FILE_COUNT = 3

const UploadReceipt = ({ filesUploaded, setFilesUploaded }) => {
  const [notifications, setNotifications] = useState([])
  const fileInputRef = useRef(null)
  const { state } = useContext(AppContext)

  const allFilesUploaded = [
    ...state.flexmed.beneficiaryDocuments,
    ...state.flexmed.documents,
    ...filesUploaded,
  ]

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  const openFileChooser = () => {
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  const handleAlreadyUploaded = setNotifications => file => {
    setNotifications(notificationsList => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const handleFileRead = event => {
    const tempFilesUploaded = [...event.target.files]
    let initialNotifications = []
    if (tempFilesUploaded.length + filesUploaded.length > MAX_FILE_COUNT)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${MAX_FILE_COUNT} files.`}
        />
      )
    else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < MAX_FILE_SIZE_IN_BYTES)
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            setFilesUploaded,
            null,
            handleAlreadyUploaded(setNotifications),
            "receipt",
            allFilesUploaded
          )
        else
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 5MB. Please upload a file or photo less than 52MB."
            />
          )
      }
    setNotifications(initialNotifications)
  }

  return (
    <Fragment>
      {/* <p className="is-size-6">Upload Receipt</p> */}
      <Dropzone
        fileInputRef={fileInputRef}
        handleFileChooser={openFileChooser}
        filesUploaded={filesUploaded}
        handleFileRead={handleFileRead}
        notifications={notifications}
      />
    </Fragment>
  )
}

export default UploadReceipt
