import React, { Fragment } from "react"

export const NoReceiptsMessageMobile = ({ receipts }) => {
  if (receipts.length === 0)
    return (
      <div className="is-hidden-desktop is-hidden-tablet mb-4">
        <p className="is-size-6 has-text-grey has-text-centered">
          No receipts uploaded
        </p>
      </div>
    )
  return null
}

const NoReceiptsMessage = ({ receipts }) => {
  if (receipts.length === 0)
    return (
      <div className="is-hidden-mobile">
        <p className="is-size-6 has-text-grey pl-1">No receipts uploaded</p>
        <hr className="mt-1 has-background-light" />
      </div>
    )
  return null
}

export default NoReceiptsMessage
