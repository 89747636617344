import React, { useEffect } from "react"
import { useField, ErrorMessage, useFormikContext } from "formik"
import FormInput from "../../Elements/Form/FormInput"
import Search from "../../Search/Search"
import { values } from "lodash-es"

const EncodeReceiptItemRow = ({
  onHandleDelete,
  dataSource,
  index,
  disableSearch,
  values,
  setDisableSearch,
}) => {
  const [field, _, helpers] = useField({ name: `items[${index}].SKU` })
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (values?.encodeFullReceipt?.includes("Full")) {
      setDisableSearch(true)
    } else {
      setDisableSearch(false)
    }
  }, [values])
  return (
    <tr>
      <td className="pr-1" width="70%">
        <Search
          dataSource={dataSource}
          onSelect={product => {
            helpers.setValue(product.productTitle)
            setFieldValue(`items[${index}].code`, product.id)
          }}
          handleChange={() => {
            setFieldValue(`items[${index}].code`, "")
          }}
          placeholder="Metformin"
          field={field}
          withIcon
          module="request"
          disabled={disableSearch}
        />
        <div className="is-size-7 has-text-danger mb-1">
          <ErrorMessage name={`items[${index}].code`} />
        </div>
      </td>
      <td className="pr-1" width="25%">
        <FormInput
          name={`items[${index}].itemPrice`}
          type="number"
          className="has-text-right"
          step="0.01"
          placeholder="123.50"
          max={100000}
          isRequired
        />
      </td>
      {!disableSearch && index !== 0 ? (
        <td width="5%">
          <button
            type="button"
            className="delete is-large"
            onClick={onHandleDelete}
          />
        </td>
      ) : null}
    </tr>
  )
}

export default EncodeReceiptItemRow
