import React, { Fragment, useEffect, useState, useContext } from "react"

import FormRadio from "../../Elements/Form/FormRadio"

import { AppContext } from "../../../context/AppContext"
import benefitTypes from "./utils/benefitTypes.json"

const EncodeFullReceipt = ({ values, setFieldValue, setDisableSearch }) => {
  const {
    state: {
      flexmed: { benefitType },
    },
  } = useContext(AppContext)
  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    setSearchValue(benefitTypes[benefitType])
  }, [])

  const handleOnChange = event => {
    if (event.target.value.includes("Full")) {
      setFieldValue("items", [searchValue])
      // setFieldValue(`items[${0}].SKU`, searchValue)
      setDisableSearch(true)
    } else {
      setFieldValue(`items[${0}].SKU`, "")
      setFieldValue(`items[${0}].code`, "")
      setDisableSearch(false)
    }
  }
  return (
    <Fragment>
      <FormRadio
        name="encodeFullReceipt"
        title="Are you reimbursing your full or partial receipt?"
        // helper="Select full if all items in the receipt should be reimbursed "
        options={["Full receipt", "Partial receipt"]}
        value={values.encodeFullReceipt}
        onChange={handleOnChange}
        isRequired
      />
    </Fragment>
  )
}

export default EncodeFullReceipt
