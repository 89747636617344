import React from "react"
import ReimbursementsListRowMobile from "./ReimbursementsListRowMobile"

const ReimbursementsListMobile = ({
  receipts,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
}) => {
  return (
    <div className="is-hidden-tablet is-hidden-desktop my-1">
      {receipts.map((receipt, index) => (
        <ReimbursementsListRowMobile
          receipt={receipt}
          index={index}
          handleClickEditReceipt={handleClickEditReceipt}
          handleClickRemoveReceipt={handleClickRemoveReceipt}
        />
      ))}
    </div>
  )
}

export default ReimbursementsListMobile
