import React, { useState, useEffect } from "react"
import Layout from "../../components/Layout/Layout"
import EncodeReceipt from "../../components/FlexMed/EncodeReceipt"

export default ({ location }) => {
  const [subtitleMessage, setSubtitleMessage] = useState("")

  return (
    <Layout
      title="Encode Receipts"
      subtitle={subtitleMessage}
      seoTitle="Encode Receipt"
      process="flexmed"
    >
      <EncodeReceipt
        location={location}
        setSubtitleMessage={setSubtitleMessage}
      />
    </Layout>
  )
}
