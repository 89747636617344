import React from "react"
import FileThumbnail from "./FileThumbnail"
import ReimbursementsListButtons from "./ReimbursementsListButtons"

const ReimbursementsListRow = ({
  receipt,
  index,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
}) => {
  const { receiptNumber, uploads, dateOfPurchase, items } = receipt
  const dateOfPurchaseString = `${dateOfPurchase?.month?.value} ${dateOfPurchase?.date?.value} ${dateOfPurchase?.year}`

  const totalEncodedPriceString = items
    ?.reduce(
      (previousValue, currentValue) =>
        parseFloat(previousValue) + parseFloat(currentValue?.itemPrice),
      0
    )
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

  return (
    <tr>
      <td>
        <FileThumbnail path={uploads[0]?.path} />
      </td>
      <td>{receiptNumber}</td>
      <td>{dateOfPurchaseString}</td>
      <td>Php {totalEncodedPriceString}</td>
      <td>
        <ReimbursementsListButtons
          index={index}
          handleClickEditReceipt={handleClickEditReceipt}
          handleClickRemoveReceipt={handleClickRemoveReceipt}
        />
      </td>
    </tr>
  )
}

export default ReimbursementsListRow
