import React, { Fragment, useEffect, useState, useContext } from "react"

import FormDate from "../../Elements/Form/FormDate"
import FormRadio from "../../Elements/Form/FormRadio"

import FormInput from "../../Elements/Form/FormInput"
import FormSelect from "../../Elements/Form/FormSelect"
import { storeOptions } from "../utils/formData"

const EncodeReceiptDetails = ({ values }) => {
  //if search is disabled (if encoding full receipt) change labels of the forms

  const sortStoreOptions = (a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  }

  const finalStoreOptions = [
    ...storeOptions.sort(sortStoreOptions),
    {
      value: "Other store",
      label: "Other store",
    },
  ]

  return (
    <Fragment>
      <FormInput
        name="receiptNumber"
        label="Receipt Number"
        placeholder="ABG11456789"
        maxLength={15}
        isRequired
      />
      {/* TO DO: alphabetize and always show store options */}
      <FormSelect
        name="pharmacyName"
        label="Outlet Name"
        placeholder="Select outlet"
        isRequired
        value={values?.pharmacyName}
        options={finalStoreOptions}
      />
      <FormDate
        name="dateOfPurchase"
        values={values?.dateOfPurchase}
        isRequired
        label="Date of Purchase"
      />
    </Fragment>
  )
}

export default EncodeReceiptDetails
