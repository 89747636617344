import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FieldArray } from "formik"

import FlexMedReceiptInputRow from "./EncodeReceiptItemRow"
import FlexMedReceiptInputRowHeader from "./EncodeReceiptItemRowHeader"

import { useFlexMedProducts } from "../../Epharmacy/hooks/useFlexMedProducts"

const EncodeReceiptItems = ({ values, disableSearch, setDisableSearch }) => {
  const data = useFlexMedProducts()

  return (
    <FieldArray name="items">
      {arrayHelpers => (
        <table>
          <FlexMedReceiptInputRowHeader disableSearch={disableSearch} />
          {values?.items?.map((item, index) => (
            <FlexMedReceiptInputRow
              item={item}
              onHandleDelete={() => arrayHelpers.remove(index)}
              dataSource={{
                type: "graphql",
                data: data,
                lookupKeys: ["productTitle"],
              }}
              index={index}
              disableSearch={disableSearch}
              setDisableSearch={setDisableSearch}
              values={values}
            />
          ))}
          {values?.items?.length < 10 && (
            <p
              className="has-text-primary has-text-weight-bold is-size-6"
              onClick={() => {
                arrayHelpers.push({
                  SKU: "",
                  code: "",
                  itemPrice: "",
                })
              }}
            >
              {!disableSearch && <u>+ Add new item</u>}
            </p>
          )}
        </table>
      )}
    </FieldArray>
  )
}

export default EncodeReceiptItems
