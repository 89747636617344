import React from "react"

const AddReceiptButtonMobile = ({ handleClickAddReceipt }) => (
  <button
    type="button"
    className="button is-fullwidth mb-1 is-primary is-hidden-desktop is-hidden-tablet"
    onClick={handleClickAddReceipt}
  >
    + Add New Receipt
  </button>
)

export default AddReceiptButtonMobile
