import React, { Fragment, useState, useContext, useRef } from "react"
import EncodeOnlinePurchaseDocuments from "./EncodeOnlinePurchaseDocuments"
import FormRadio from "../../Elements/Form/FormRadio"
import Dropzone from "../../Upload/Dropzone"
import UploadErrorNotification from "../../Upload/Dropzone/UploadErrorNotification"
import { isIeOrEdge } from "../../../services/browserCompatibility"
import { AppContext } from "../../../context/AppContext"
import { uploadDocument } from "../../Upload/services/uploadDocuments"

const MAX_FILE_SIZE_IN_BYTES = 5242880
const MAX_FILE_COUNT = 6

const EncodeOnlinePurchase = ({ values, filesUploaded, setFilesUploaded }) => {
  const fileInputRef = useRef(null)
  const [notifications, setNotifications] = useState([])

  const { state } = useContext(AppContext)

  const allFilesUploaded = [
    ...state.flexmed.beneficiaryDocuments,
    ...state.flexmed.documents,
    ...filesUploaded,
  ]

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  const openFileChooser = () => {
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  const handleAlreadyUploaded = setNotifications => file => {
    setNotifications(notificationsList => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const closeNotifications = () => {
    setNotifications([])
  }

  const handleFileRead = event => {
    closeNotifications()
    const tempFilesUploaded = [...event.target.files]
    const initialNotifications = []
    if (tempFilesUploaded.length + filesUploaded.length > MAX_FILE_COUNT)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${MAX_FILE_COUNT} files.`}
        />
      )
    else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < MAX_FILE_SIZE_IN_BYTES)
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            setFilesUploaded,
            null,
            handleAlreadyUploaded(setNotifications),
            "pp",
            allFilesUploaded
          )
        else
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 5MB. Please upload a file or photo less than 5MB."
            />
          )
      }
    setNotifications(initialNotifications)
  }

  return (
    <Fragment>
      <FormRadio
        name="onlinePurchase"
        title="Is this an online purchase?"
        value={values.onlinePurchase}
        options={["Yes", "No"]}
        isRequired
        isInline
      />
      {values.onlinePurchase == "Yes" && (
        <Fragment>
          <EncodeOnlinePurchaseDocuments
            onlinePurchase={values.onlinePurchase}
          />
          <Dropzone
            fileInputRef={fileInputRef}
            handleFileChooser={openFileChooser}
            filesUploaded={filesUploaded}
            handleFileRead={handleFileRead}
            notifications={notifications}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default EncodeOnlinePurchase
